import classNames from "classnames/bind";
import PropTypes from "prop-types";
import {useCallback, useContext, useEffect, useState} from "react";
import * as React from "react";
import {SliderApiContext} from "./index";
import * as paginationStyles from "./slider-pagination.module.css";

const cx = classNames.bind(paginationStyles);

const SliderPagination = ({className}) => {
    const sliderApi = useContext(SliderApiContext);

    const scrollToSnap = useCallback(
        (snapIndex) => {
            if (!sliderApi) {
                return;
            }

            sliderApi.scrollTo(snapIndex);
        },
        [sliderApi],
    );

    const [snaps, setSnaps] = useState([]);
    const [selectedSnapIndex, setSelectedSnapIndex] = useState(0);

    const updateSnaps = useCallback(() => {
        if (!sliderApi) {
            return;
        }

        setSnaps(sliderApi.scrollSnapList());
        setSelectedSnapIndex(sliderApi.selectedScrollSnap());
    }, [sliderApi]);

    const updateSelectedSnapIndex = useCallback(() => {
        if (!sliderApi) {
            return;
        }

        setSelectedSnapIndex(sliderApi.selectedScrollSnap());
    }, [sliderApi]);

    useEffect(() => {
        if (!sliderApi) {
            return () => {};
        }

        sliderApi.on("init", updateSnaps);
        sliderApi.on("reInit", updateSnaps);

        sliderApi.on("select", updateSelectedSnapIndex);

        return () => {
            sliderApi.off("reInit", updateSnaps);
            sliderApi.off("reInit", updateSnaps);

            sliderApi.off("select", updateSelectedSnapIndex);
        };
    }, [sliderApi, updateSnaps, updateSelectedSnapIndex]);

    return (
        <div className={cx({sliderPagination: true}, className)}>
            {snaps.length > 1 &&
                snaps.map((snap, idx) => {
                    const isSelected = selectedSnapIndex === idx;

                    return (
                        <button
                            className={cx({sliderPagination__button: true, active: isSelected})}
                            key={snap}
                            onClick={() => scrollToSnap(idx)}
                            type="button"
                            aria-label={`Перейти к слайду ${idx + 1}`}
                        />
                    );
                })}
        </div>
    );
};

SliderPagination.defaultProps = {
    className: "",
};

SliderPagination.propTypes = {
    className: PropTypes.string,
};

export default SliderPagination;
