import classNames from "classnames/bind";
import PropTypes from "prop-types";
import * as React from "react";
import useEmblaCarousel from "embla-carousel-react";
import SectionTitle from "../../Layout/Section/Title";
import * as sliderStyles from "./slider.module.css";

const cx = classNames.bind(sliderStyles);

export const SliderApiContext = React.createContext(null);

const Slider = ({children, sliderClassName, containerClassName, options, title, navigation, pagination, alignLeft}) => {
    const defaultOptions = {
        align: "start",
        containScroll: "trimSnaps",
    };

    const [emblaRef, emblaApi] = useEmblaCarousel({...defaultOptions, ...options});

    return (
        <SliderApiContext.Provider value={emblaApi}>
            <div>
                {(title || navigation) && (
                    <div className={cx({slider__header: true, slider__header_left: alignLeft})}>
                        <SectionTitle>{title}</SectionTitle> {navigation}
                    </div>
                )}
                <div ref={emblaRef} className={cx(sliderClassName)}>
                    <div className={cx(containerClassName, "embla__container")}>{children}</div>
                </div>
            </div>
            {pagination && <div className={sliderStyles.slider__footer}>{pagination}</div>}
        </SliderApiContext.Provider>
    );
};

Slider.defaultProps = {
    children: [],
    sliderClassName: sliderStyles.slider__wrapper,
    containerClassName: sliderStyles.slider__container,
    options: {},
    title: "",
    navigation: null,
    pagination: null,
    alignLeft: false,
};

Slider.propTypes = {
    children: PropTypes.node,
    sliderClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    containerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    options: PropTypes.objectOf(PropTypes.object),
    title: PropTypes.string,
    navigation: PropTypes.node,
    pagination: PropTypes.node,
    alignLeft: PropTypes.bool,
};

export default Slider;
